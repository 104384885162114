<template>
  <div class="dropWrapper">

    <template v-if="!compact">
      <h2><i class="fa-duotone fa-files"></i> Dateien hochladen</h2>
      <p>Gewünschte Dateien in das Fenster ziehen oder wählen Sie ein oder mehrere Dokumente aus, die Sie hochladen
        möchten.</p></template>
    <div class="upload" :style="(compact) ? '':'margin-bottom: 48px;'">
      <ul v-if="!compact">
        <li v-for="file in files" :key="file.id">
          <span>{{ file.name }}</span> -
          <span>{{ file.size }}</span> -
          <span v-if="file.error">{{ file.error }}</span>
          <span v-else-if="file.success">Upload beendet!</span>
          <span v-else-if="file.active">Upload läuft...</span>
          <span v-else-if="!!file.error">{{ file.error }}</span>
          <span v-else></span>
        </li>
      </ul>
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
        <h3><i class="fa-duotone fa-files"></i> Dateien in das Fenster ablegen</h3>
      </div>
      <file-upload
        class="btn btn-outline-secondary"
        :class="(compact) ? 'btn-sm':'me-3'"
        :post-action="postAction"
        extensions="gif,jpg,jpeg,png,webp,pdf,json,svg,txt,xml,xls,xlsx,doc,docx,ppt,pptx,zip,rar,7z,mp3,mp4,avi,flv,wmv,mpg,mov,eml,csv,msg"
        accept="image/png,image/gif,image/svg,image/jpeg,image/webp,application/pdf,image/svg+xml,text/plain,application/json,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip,application/x-rar-compressed,application/x-7z-compressed,audio/mpeg,audio/mp4,video/x-msvideo,video/x-flv,video/x-ms-wmv,video/mpeg,video/quicktime,message/rfc822,text/csv"
        :multiple="true"
        :data="fileInfo"
        :drop="true"
        :drop-directory="false"
        :size="1024 * 1024 * 50"
        v-model="files"
        @input-filter="inputFilter"
        @input-file="inputFile"
        :headers="headers"
        ref="upload">
        <i class="fa-duotone fa-upload"></i> Dateien auswählen
      </file-upload>
      <button type="button" class="btn btn-outline-danger me-4" v-if="$refs.upload && $refs.upload.active && upload"
              @click.prevent="upload.active = false">
        <i class="fa-duotone fa-close"></i> Upload stoppen
      </button>
    </div>
  </div>
</template>
<script>
import {ref, computed, getCurrentInstance} from 'vue';
import FileUpload from 'vue-upload-component'
import {useStore} from 'vuex';

export default {
  name: 'UploadComponent',
  components: {
    FileUpload,
  },
  props: {
    customerId: '',
    taskId: '',
    eventId: '',
    fileInfo: {},
    draftId: '',
    compact: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: ''
    }
  },
  methods: {
    checkUpload() {
      if (!this.upload) {
        this.upload = {
          active: false
        }
      }
      if (!this.customerId) {
        this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
        this.upload.active = true;
      } else {
        if (this.taskId) {
          this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload/' + this.taskId + "/issue"
        } else {
          this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
        }
        this.upload.active = true;
      }
      if (this.eventId) {
        this.postAction = this.$store.state.apiUrl + 'event/' + this.eventId + '/upload'
        this.upload.active = true;
      }
      if (this.draftId) {
        this.postAction = this.$store.state.apiUrl + 'draft/' + this.draftId + '/upload'
        this.upload.active = true;
        this.$store.dispatch("loadDraftAttachments");
      }
    },
    denyUpload() {
      this.upload.active = false;
    },
  },
  data() {
    return {
      postAction: '',
      upload: {
        active: false
      },
      reference: 'upload',
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  setup(props, context) {
    const internalInstance = getCurrentInstance();
    const globalT = internalInstance?.appContext.config.globalProperties.$t;
    const upload = ref(null)
    const files = ref([])
    const store = useStore();
    const fileInfo = computed(() => {
      const info = { ...props.fileInfo };
      if (props.filename) {
        info.title = props.filename;  // Ensure full value is included
      }
      return info;
    });
    function inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    }

    function inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        const triggerReloadTask = new CustomEvent('fileAddedInput');
        window.dispatchEvent(triggerReloadTask);
      }
      if (newFile && oldFile) {
        if (newFile.active === false) {
          const triggerReloadTask = new CustomEvent('triggerReloadFiles');
          window.dispatchEvent(triggerReloadTask);
        }
      }
      if (!newFile && oldFile) {
        const triggerReloadTask = new CustomEvent('fileRemovedInput');
        window.dispatchEvent(triggerReloadTask);
      }
      if (newFile && oldFile && newFile.error !== oldFile.error) {
        store.dispatch('toastError', globalT('uploadError.' + newFile.error));
      } else if (newFile && !oldFile && newFile.error) {
        store.dispatch('toastError', globalT('uploadError.' + newFile.error));
      }
    }

    return {
      files,
      upload,
      inputFilter,
      inputFile,
      fileInfo
    }
  },
  created() {
    if (!this.customerId) {
      this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
    } else {
      if (this.taskId) {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload/' + this.taskId + "/issue"
      } else {
        this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
      }
    }
    if (this.eventId) {
      this.postAction = this.$store.state.apiUrl + 'event/' + this.eventId + '/upload'
    }
    if (this.draftId) {
      this.postAction = this.$store.state.apiUrl + 'draft/' + this.draftId + '/upload'

    }
    window.addEventListener('fileAddedInput', this.checkUpload)
    window.addEventListener("fileRemovedInput", this.denyUpload)
  },
  watch: {
    customerId() {
      if (!this.draftId) {
        if (!this.eventId) {
          if (!this.customerId) {
            this.postAction = this.$store.state.apiUrl + 'files/uploadTemp'
          } else {
            this.postAction = this.$store.state.apiUrl + 'customer/' + this.customerId + '/upload'
          }
        } else {
          this.postAction = this.$store.state.apiUrl + 'event/' + this.eventId + '/upload'
        }
      } else {
        this.postAction = this.$store.state.apiUrl + 'draft/' + this.draftId + '/upload'
      }
    }
  }
}
</script>
<style>
label[for=file] {
  cursor: pointer;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
  text-transform: initial;
}
</style>
