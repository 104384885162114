<template>
  <template v-if="mode === 'listSort'">
    <div class="lineitem l-license fromPlugin pluginDfb isSort"><span
      :class="(sortDirections.trainerLicenseLicense) ? 'active' : ''"
      @click="emitSort('trainerLicenseLicense')">
      <i v-if="sortDirections.trainerLicenseLicense === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.trainerLicenseLicense === 'desc'" class="fa-duotone fa-arrow-down"></i>
      <span tooltip="Lizenz" position="left"><i class="fa-duotone fa-address-card"></i></span></span>
    </div>
    <div class="lineitem l-licenseNo fromPlugin pluginDfb isSort"><span @click="emitSort('trainerLicenseLicenseNo')"
                                                                        :class="(sortDirections.trainerLicenseLicenseNo) ? 'active' : ''">
      <i v-if="sortDirections.trainerLicenseLicenseNo === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.trainerLicenseLicenseNo === 'desc'" class="fa-duotone fa-arrow-down"></i>
      <span tooltip="Lizenznummer" position="left"><i class="fa-duotone fa-rectangle-barcode"></i></span></span></div>
    <div class="lineitem l-licenseVer fromPlugin pluginDfb isSort"><span @click="emitSort('trainerLicenseVerified')"
                                                                         :class="(sortDirections.trainerLicenseVerified) ? 'active' : ''">
      <i v-if="sortDirections.trainerLicenseVerified === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.trainerLicenseVerified === 'desc'" class="fa-duotone fa-arrow-down"></i>
      <span tooltip="Verifizierung Lizenz" position="left"><i class="fa-duotone fa-file-certificate"></i></span></span>
    </div>
  </template>
  <template v-if="mode === 'listHeadline'">
    <div class="lineitem l-license fromPlugin pluginDfb" style="text-align: center;">
      <b v-if="customer.trainerLicenseLicense">{{ customer.trainerLicenseLicense }}</b>
    </div>
    <div class="lineitem l-licenseNo fromPlugin pluginDfb">
      <template v-if="customer.trainerLicenseLicenseNo">{{ customer.trainerLicenseLicenseNo }}</template>
    </div>
    <div class="lineitem l-licenseVer fromPlugin pluginDfb">
      <template v-if="customer.trainerLicenseLicenseNo">{{
          $t('plugins.dfb.status.' + customer.trainerLicenseVerified)
        }}
      </template>
    </div>
  </template>
  <template v-if="mode ==='viewLicense'">
    <div class="card-body">
      <div class="form-group">
        <label>Lizenz-Nummer</label>
        <input class="form-control" type="text" v-model="editLicense.license_no"/>
      </div>
      <div class="form-group">
        <label>Lizenz</label>
        <input class="form-control" type="text" v-model="editLicense.license"/>
      </div>
      <div class="form-group">
        <label>Verifizierung</label>

        <div class="form-group">
          <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
            <label class="btn btn-outline-primary"><input
            type="radio" v-model="editLicense.verified" autocomplete="off" value="verified"> Verifiziert</label><label
            class="btn btn-outline-primary"><input
            type="radio" v-model="editLicense.verified" autocomplete="off" value="denied"> Abgelehnt</label>
          </div>
        </div>
        <div class="form-group">
          <label>Kommentar</label>
          <textarea class="form-control" v-model="editLicense.verified_comment"></textarea>
        </div>
        <div class="form-group">
          <button class="btn btn-outline-primary" @click="saveLicense()">Speichern</button>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import axios from "axios";

export default {
  props: {
    customer: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      required: true
    },
    sortDirections: {
      type: Object,
      required: false
    },
  },
  emits: ['sort'],
  data() {
    return {
      show: false,
      editLicense: {
        license_no: '',
        license: '',
        verified: '',
        verified_comment: ''
      }
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    getLicense() {
      // get license with axios from /customer/id/trainerLicense
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customer.id + "/trainerLicense", {headers}).then((r) => {
        this.editLicense = r.data.result.data;
      });
    },
    saveLicense() {
      // save license with axios to /trainer/license/{id}
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "trainer/license/" + this.customer.id, this.editLicense, {headers}).then((r) => {
        this.editLicense = r.data.result.data;
      });
    },
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }
      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }
      // Emit the clicked type and direction
      this.$emit('sort', {type: clickedType, direction: this.sortDirections[clickedType]});
    }
  },
  created() {
    if (this.mode === 'viewLicense') {
      this.getLicense();
    }
  }
}
</script>
